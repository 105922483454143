// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "channels";
// import $ from 'jquery';
import { Collapse } from "bootstrap";
import "../src/application";
import toastr from "toastr";
import smoothscroll from "smoothscroll-polyfill";

toastr.options = {
  closeButton: true,
};
window.toastr = toastr;

Rails.start();
// Turbolinks.start()
ActiveStorage.start();

const Application = {
  /**
   * 通知の既読処理を行って表示を既読にする
   *
   */
  updateNotificationUnread() {
    $.ajax({
      url: "/update_unread",
      type: "PUT",
    });
    const notificationBadge = document.querySelector(".notification_badge");
    if (notificationBadge != null) {
      notificationBadge.style.display = "none";
    }
  },
  /**
   * タッチデバイスでの:hoverの挙動を無効化
   *
   */
  disableHoverOnTouchDevice() {
    const touchEnabled =
      "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (touchEnabled) {
      try {
        for (const si in document.styleSheets) {
          const styleSheet = document.styleSheets[si];
          if (!styleSheet.cssRules) continue;

          for (const ri = styleSheet.cssRules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.cssRules[ri].selectorText) continue;

            if (styleSheet.cssRules[ri].selectorText.match(":hover")) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) { }
    }
  },
  /**
   * 画面の幅が一定数以下の時にヘッダーのメニューのみを追従する
   *
   */
  followHeaderMenu() {
    window.onscroll = () => {
      const scroll = document.scrollingElement.scrollTop;
      if (scroll > 45) {
        document.querySelector("#globalHeader .menu_box").classList.add("fixed");
      } else {
        document.querySelector("#globalHeader .menu_box").classList.remove("fixed");
      }
    };
  },
  /**
   * 通知リスト・マイページメニューを閉じる
   *
   */
  controllHeaderCollapse() {
    const notificationList = document.getElementById("notificationList");
    const myMenu = document.getElementById("mypageMenu");

    window.onclick = (event) => {
      // 通知リストが開いている場合、通知リスト以外の箇所をクリックすると閉じる
      if (notificationList.classList.contains("show") && event.target != notificationList) {
        const notificationCollapse = Collapse.getInstance(notificationList);
        notificationCollapse.hide();
      }
      // マイページメニューが開いている場合、マイページメニュー以外の箇所をクリックすると閉じる
      if (myMenu.classList.contains("show") && event.target != myMenu) {
        const myMenuCollapse = Collapse.getInstance(myMenu);
        myMenuCollapse.hide();
      }
    };
  },
  /**
   * ヘッダーのカテゴリ選択幅を可変に
   *
   */
  changeCategoryWidth() {
    const target = document.getElementById("headerSelectCategory");
    if (target == null) return;
    const catLength = target.selectedOptions[0].textContent.length;
    const catWidth = catLength + 4 + "em";
    target.style.width = catWidth;
  },
  /**
   * スマホのみ：スクロールでヘッダー検索窓の表示・非表示
   *
   */
  controllSearchBar() {
    let startPos = 0;
    const searchBox = document.getElementById("content_mainNav");

    window.addEventListener("scroll", () => {
      const scroll = document.scrollingElement.scrollTop;
      if (scroll < startPos || scroll < 100) {
        //上にスクロールしたとき検索窓を表示する
        //iPhoneで上スクロール時ページ最上部に来るとバウンスすることで下スクロール判定となるバグを回避のために「winScrollTop < 100」の条件追加
        searchBox.classList.remove("hide");
      } else {
        //下にスクロールしたとき検索窓を非表示にする
        searchBox.classList.add("hide");
      }
      startPos = document.scrollingElement.scrollTop;
    });
  },
  controllPageTopButton() {
    //ページトップボタンをスクロール表示＆SP用検索ボタンの位置調整
    const topBtn = document.querySelectorAll("#pagetop");
    //スクロールが300に達したらボタン表示
    window.onscroll = () => {
      const scroll = document.scrollingElement.scrollTop;
      if (scroll > 300) {
        topBtn.forEach((btn) => {
          btn.classList.remove("fadeout");
          btn.classList.add("fadein");
        });
      } else {
        topBtn.forEach((btn) => {
          btn.classList.remove("fadein");
          btn.classList.add("fadeout");
        });
      }
    };
  },
  /**
   * ヘッダーから検索した時にsubmitする(PC版のみサブカテゴリのパラメータを消去)
   *
   */
  searchServicesFromHeader() {
    const form = document.getElementById("serviceSearchForm");
    const windowWidth = window.innerWidth;
    if (windowWidth > 768) {
      // PC版であれば、hiddenSubCategoryのvalueを消してsubmit
      const hiddenSubCategory = document.getElementById("hiddenSubCategory");
      hiddenSubCategory.remove();
    }
    form.submit();
  },
  /**
   * 引数に入れたidを持つ要素にスクロールする
   *
   * @param {*} id
   */
  scrollToElement(id) {
    smoothscroll.polyfill();
    const elementId = id.startsWith("#") ? id.slice(1) : id;
    const scrollElement = document.getElementById(elementId);
    scrollElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  },
  /**
   * iOSで2回タップしないとリンクが動作しない不具合の対策
   *
   */
  onTouchStart() {
    document.querySelectorAll("a,button").forEach((element) => {
      element.addEventListener("touchstart", () => { });
    });
  },

  /**
   * 会員区分選択時の挙動
   *
   */
  toggleUserStatus() {
    const checkboxes = document.querySelectorAll(".check_userstatus input[type=checkbox]");

    function handleCheckboxChange(event) {
      const checkbox = event.target;
      const label = checkbox.parentElement;

      if (checkbox.checked) {
        label.classList.remove("is_uncheck");
      } else {
        label.classList.add("is_uncheck");
      }
    }

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", handleCheckboxChange);
    });
  },
};

document.addEventListener("DOMContentLoaded", () => {
  // ページロード後にグローバル定数にApplicationを追加する
  Object.defineProperty(globalThis, "Application", {
    value: Application,
    writable: false,
  });
  Application.followHeaderMenu();
  Application.disableHoverOnTouchDevice();
  Application.controllHeaderCollapse();
  Application.controllSearchBar();
  Application.controllPageTopButton();
  Application.changeCategoryWidth();
  Application.onTouchStart();
  Application.toggleUserStatus();
});
